<template>
<ModalContent
  name="fieldChecksModal"
  ref="modal"
  width="42%"
  v-bind="$attrs"
  v-on="$listeners"
  :showFooter="false"
>
  <template v-if="field" #title>
    <span class="truncate">{{ field.name }} Checks</span>
    
  </template>
  <template v-if="field" #content>
    <div class="h-96 overflow-y-auto scrol-bar">
      <ListView :listItems="list" :showSearch="true" @handleFilterResult="$emit('handleFilterResult', $event)">
        <template #item="{ item }">
          <router-link
            :to="{
              name: 'check admin details',
              params: { id: item.id },
            }"
            class="text-primary w-full"
          >{{ item.name }}</router-link>
        </template>
      </ListView>
    </div>
  </template>
</ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view/index.vue";

export default {
  name: 'field-checks-modal',
  components: {
    ModalContent,
    ListView,
  },
  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    list() {
      if (!this.field?.checks) return [];
      return this.field.checks.map((el) => ({
        id: Object.entries(el)[0][0],
        name: Object.entries(el)[0][1],
      }));
    }
  },
}
</script>

<style lang="scss" scoped>
</style>