//(fixthis)
import SubHeader from "@/components/SubHeader";
// import dvTable from "@/components/devliumComponent/field-table/dv-table";
import Button from "@/components/button";
import Sinput from "@/components/input";
import NeoPagination from "@/components/pagination";
import Loader from "@/components/loader";
import LoaderFull from "@/components/loader-full";
// import { mapActions, mapGetters } from "vuex"
import modalContent from "@/components/modal-content";
import axios from "@/axios";
import { FETCH_FIELDS } from "@/modules/fields-admin/store";
import Dropdown from "@shared/components/dropdown-base";
import DelviumLoader from "@shared/loader";
import FieldChecksModal from "../components/fieldChecksModal.vue";
import FieldBlocksModal from "../components/fieldBlocksModal.vue";
import Table from "@shared/dashboard-components/table-large";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";

export default {
    name: "field-table",
    components: {
        SubHeader,
        // dvTable,
        Button,
        NeoPagination,
        Loader,
        Sinput,
        "loader-full": LoaderFull,
        modalContent,
        DelviumLoader,
        Dropdown,
        FieldChecksModal,
        Table,
        FieldBlocksModal,
    },
    created() {
        return this.$route.path;
    },
    title: "Field Table",

    data() {
        return {
            searchTimeout: 500,
            searchKeyword: "",
            searchLoading: {},
            columns: [],
            // columnFilterOptions: {},
            columnFilterOptionsAsync: {},
            items: [],
            showInputTypeModal: false,
            fieldData: [],
            selectedField: null,
            isLoading: false,
            filterTypevalue: { label: "All fields", value: false },
            FilteredResult: [],
            isCloning: false,
            selectedRow: null,
            fieldFilters: [
                { label: "All fields", value: false },
                { label: "Special fields", value: true },
            ],
            fetchingFieldType: false,
            fieldChecks: null,
            fieldBlocks: null,
            activeRow: null,
            fetchingFieldBlocks: false,
            paginationOptions: {
                enabled: true,
                mode: "remote",
            },
            pageData: {
                total: 0,
            },
            currentPageData: {
                page: 1,
                count: 10,
            },
            serverParams: null,
            searchParams: {
                block: "",
                check: "",
            },
        };
    },
    computed: {
        fieldDataFiltered() {
            return this.fieldData.length ? this.fieldData.filter((fld) => fld.ui_label.toLowerCase().includes(this.searchKeyword.toLowerCase()) || fld?.ui_label?.split("_").join(" ").toLowerCase().includes(this.searchKeyword.toLowerCase())) : [];
        },
        // getFilteredResult() {
        //     let searchValue = this.filterTypevalue;
        //     let newFields = this.items;
        //     if (searchValue && searchValue === "Special fields") {
        //         newFields = this.items.filter((el) => el.special_type && el.special_type.length);
        //     }
        //     return newFields;
        // },
        filterOptions() {
            let result = this.getFilterOptions(this.columnOptions, this.items);
            return result;
        },
        columnOptions() {
            return [
                { label: "Name", field: "name", config: { filter: true, type: "link" }, tdClass: "truncate max-w-xs" },
                { label: "Label", field: "label", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Checks", field: "checks_count", config: { filter: false, type: "show_info" }, tdClass: "truncate max-w-xs" },
                { label: "Blocks", field: "blocks_count", config: { filter: false, type: "show_blocks" }, tdClass: "truncate max-w-xs" },
                { label: "Type", field: "ui_label", query_key: "field_type", prefetchedOptions: this.getTypeOptions, config: { filter: true }, tdClass: "truncate max-w-sm" },
                { label: "Placeholder", field: "placeholder", config: { filter: true }, tdClass: "truncate max-w-sm" },
                {
                    label: "Source",
                    field: "is_custom",
                    query_key: "source",
                    // {name: 'Restricted', value: 'restricted', id: 3 }
                    prefetchedOptions: [
                        // { name: "Custom", value: "custom", id: 1 },
                        { name: "Custom", value: "custom", id: 1 },
                        { name: "System Fields", value: "system_fields", id: 2 },
                        { name: "Cloned System Fields", value: "cloned_system_fields", id: 3 },
                        
                    ],
                    config: { filter: true, type: "source" },
                    tdClass: "truncate max-w-sm",
                },
                {
                    label: "Clone",
                    field: "clone",
                    config: {
                        type: "clone",
                        filter: false,
                    },
                },
            ].map((el) => ({ ...el, config: { ...el.config, isLoading: !!this.searchLoading[el.field] || false } }));
        },

        getTypeOptions() {
            let items = this.fieldData.map((el) => ({ id: el.id, value: el.id, name: el.ui_label }));
            return items;
        },
        getFieldBlocks() {
            return this.fieldBlocks?.filter((el) => el?.name?.toLowerCase().includes(this.searchParams.block.toLowerCase()));
        },
        getFieldChecks() {
            return { ...this.fieldChecks, checks: this.fieldChecks?.checks.filter((el) => Object.entries(el)[0][1]?.toLowerCase()?.includes(this.searchParams.check.toLowerCase())) };
        },
        // fieldData() {
        //     return this.$store.getters.fieldsType;
        // },
        // columnFilterOptions() {
        //     return this.getFilterOptions(
        //         this.columnOptions,
        //         this.items
        //     )
        // }
    },

    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
            // this.tenant_id = this.$store.getters.getTenantId
        }
        this.isLoading = true;
        await this.$store.dispatch(FETCH_FIELDS);
        this.fieldData = this.$store.getters.fieldsType;
        await this.fetchFields();
        // await this.fetchFieldsTypes()
    },
    methods: {
        // async fetchFieldsTypes() {
        //     let result = await axios.get('/fields/types')
        //     this.fieldData = result.data.field_types
        // },

        async fetchFields() {
            this.isLoading = true;
            let filterParams = {};
            const getFilterValue = (key) => {
                if (["field_type", "source"].includes(key)) {
                    console.log("this.serverParams :>> ", this.serverParams);
                    return this.serverParams[key]["value"];
                } else return this.serverParams[key]["name"];
            };
            if (this.serverParams) {
                Object.keys(this.serverParams).map((key) => {
                    filterParams[key] = this.serverParams[key] ? getFilterValue(key) : delete filterParams[key];
                });
            }
            let url = `/fields/${this.$store.getters.getTenantId}/all?page=${this.currentPageData.page || 1}&&count=${this.currentPageData.count || 10}`;

            let { data } = await axios.get(url, { params: { ...filterParams, special_fields: this.filterTypevalue.value || false } });
            this.items = data.fields;
            this.pageData = data?.page;
            this.isLoading = false;
            this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data?.fields);
        },
        isRestrictedOrCustom(field) {
            if (field.source === 'system_fields') {
                return "System Fields";
            } else if (field.source === 'cloned_system_fields') {
                return "Cloned System Field";
            } else if (field.source === 'custom'){
                return "Custom";
            }
        },
        hanldeCloseModal() {
            // this.$modal.hide("field-modal");
            this.$refs["add_field_modal"].hideModal();
        },
        async handleShowModal() {
            this.$refs["add_field_modal"].showModal();
            this.fetchingFieldType = true;
            this.selectedField = null;
            // this.$modal.show("field-modal");
            await this.$store.dispatch(FETCH_FIELDS);
            this.fieldData = this.$store.getters.fieldsType;
            this.fetchingFieldType = false;
        },
        handleSelectField(field) {
            this.selectedField = field;
        },
        handleAddNewField() {
            this.$router.push({
                path: "/add-field",
                query: {
                    field: this.selectedField ? this.selectedField.id : "input-text",
                },
            });
        },
        closeCloseFieldModal() {
            this.$refs["clone-field-modal"].hideModal();
        },
        showCloneModal(field) {
            this.selectedRow = null;
            this.$refs["clone-field-modal"].showModal();
            this.selectedRow = field;
            let fieldData = this.fieldData.find((ele) => ele.name === this.selectedRow.type);
            this.selectedRow = { ...this.selectedRow, is_cascaded: fieldData.has_options };
        },
        getIcon() {
            // return require(`@/assets${this.fieldData.find((ele) => ele.name === field_name).icon}`);
            return require(`@/assets/icons/svg/input-text.svg`);
        },
        handleRowClick(data) {
            this.$router.push({
                name: "Field Setting",
                params: { id: data.id },
            });
        },
        async handleCloneRow(row, cloneType) {
            this.selectedRow = null;
            this.closeCloseFieldModal();
            if (row && cloneType) {
                this.isCloning = true;
                let payload = {
                    deep_clone: cloneType === "deep-clone" ? true : false,
                };
                try {
                    let url = `/fields/${row.id}/clone`;
                    let result = await axios.post(url, payload);
                    if (result) {
                      this.$toast.success(result.data.message);
                      let cloneData = result.data;
                      let field_data = await this.fetchFieldData(
                        cloneData.field_id
                      );
                      this.items.push(field_data);
                      this.$router.push({
                        name: "Field Setting",
                        params: { id: field_data.id },
                      });
                    }
                    
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "failed to clone field");
                }
                this.isCloning = false;
            }
        },
        async fetchFieldData(fieldId) {
            let url = `/fields/${this.$store.getters.getTenantId}/field/${fieldId}`;
            let result = await axios.get(url);
            return result.data;
        },

        filterDataByFieldType(value) {
            if (value) {
                this.filterTypevalue = value;
                this.currentPageData.page = 1;
                this.fetchFields();
            }
        },
        setFieldChecks(payload) {
            this.searchParams.check = "";
            if (payload && payload.checks_count > 0) {
                this.fieldChecks = payload;
                this.$modal.show("fieldChecksModal");
            }
        },
        async setFieldBlocks(payload) {
            if (payload && payload.blocks_count > 0) {
                this.searchParams.block = "";
                this.activeRow = payload;
                this.$modal.show("fieldBlocksModal");
                this.fetchingFieldBlocks = true;
                try {
                    let url = `/fields/${payload.id}/blocks`;
                    let { data } = await axios.get(url);
                    this.fieldBlocks = data.blocks;
                } catch (error) {
                    console.log(">>>>>errow while fetching blocks", error);
                }

                this.fetchingFieldBlocks = false;
            }
        },
        async onPageChange(data, reset = false) {
            let { currentPerPage, currentPage } = data;
            this.currentPageData = {
                page: currentPage,
                count: currentPerPage,
            };
            if (reset) this.currentPageData.page = 1;
            await this.fetchFields();
        },
        async onColumnFilters({ columnFilters }) {
            this.currentPageData.page = 1;
            this.serverParams = columnFilters ? { ...columnFilters } : null;
            await this.fetchFields();
        },
        async clearFilters() {
            this.serverParams = null;
            await this.fetchFields();
        },
        getFilterOptions(columns, rows) {
            return columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => ({ id: uuid.v4(), name: el }));
                return result;
            }, {});
        },

        getSearchOptionsTick(payload) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.columnFilterOptionsAsync = null;
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, payload.column.field, true);
                try {
                    const requestData = {
                        ...this.serverParams,
                        [payload.column.query_key || payload.column.field]: payload.value,
                    };
                    const { data } = await axios.get(`fields/${this.$store.getters.getTenantId}/all`, {
                        params: requestData,
                    });
                    this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data.fields);
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, payload.column.field, false);
            }
        },
        handleFilterResult(data, type) {
            this.searchParams[type] = data;
        },
    },
};
