<template>
    <ModalContent name="fieldBlocksModal" ref="modal" width="42%" v-bind="$attrs" v-on="$listeners" :showFooter="false">
        <template v-if="field" #title> {{ field.name }} Blocks </template>
        <template #content>
            <div v-if="isLoading" class="h-96 flex items-center justify-center"><Loader /></div>
            <div v-else class="h-96 overflow-y-auto scrol-bar">
                <ListView :listItems="blocks" :showSearch="true" @handleFilterResult="$emit('handleFilterResult', $event)">
                    <template #item="{ item }">
                        <router-link
                            :to="{
                                name: 'block-admin-details',
                                params: { id: item.id },
                            }"
                            class="text-primary w-full truncate"
                            :title="item.name"
                            >{{ item.name }}</router-link
                        >
                    </template>
                </ListView>
            </div>
        </template>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view/index.vue";
import Loader from "@shared/loader"

export default {
    name: "field-checks-modal",
    components: {
        ModalContent,
        ListView,
        Loader
    },
    props: {
        field: {
            type: Object,
            default: () => {},
        },
        blocks: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
};
</script>

<style lang="scss" scoped></style>
