<template>
    <div class="flex-1 flex overflow-y-auto p-4 -m-4">
        <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
            <template #content-fieldadmin> 
                <FieldAdmin />
            </template>
            <template #content-blockadmin>
                <BlockAdmin />
            </template>
        </tabs>
    </div>
</template>

<script>
import tabs from "@/components/dashboard-components/tabs";
import FieldAdmin from "../field-lists"
import BlockAdmin from "@/pages/block-admin/block-admin-list"

export default {
    name: "field-tab",
    components: {
        tabs,
        FieldAdmin,
        BlockAdmin,
    },
    props: {},
    data() {
        return {
            tabs: {
                selected: "fieldadmin",
                list: [
                    {
                        id: "fieldadmin",
                        label: "Field Admin",
                    },
                    {
                        id: "blockadmin",
                        label: "Block Admin",
                    },
                ],
            },
        };
    },
    computed: {},
    mounted() {
        this.tabs.selected = this.$route?.query?.tab || "fieldadmin";
    },
    methods: {
        selectTab(id) {
            this.tabs.selected = id;
            this.$router.push({
                path: this.$route.path,
                query: {
                    tab: this.tabs.selected,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
